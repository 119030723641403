'use client'

// React Imports
import React, {useState} from 'react'

// Next Imports
import Link from 'next/link'
import {useParams, useRouter, useSearchParams} from 'next/navigation'

// MUI Imports
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

// Third-party Imports
import {Controller, useForm} from 'react-hook-form'
import {valibotResolver} from '@hookform/resolvers/valibot'
import type {SubmitHandler} from 'react-hook-form'
import { email, object, minLength, string, pipe, nonEmpty } from 'valibot'
import type {InferInput} from 'valibot'

// Component Imports
import Logo from '@components/layout/shared/Logo'
import CustomTextField from '@core/components/mui/TextField'

// Config Imports
import themeConfig from '@configs/themeConfig'

// Util Imports
import {useTranslation} from 'react-i18next';
import Grid from "@mui/material/Grid";
import {Locker} from "@assets/svg/Locker";
import Stack from '@mui/material/Stack';
import axios from "axios";
import Swal from "sweetalert2";

type Props = {
    t: (key: string) => string;
    handleSwitch: (value: boolean) => void;
};

type ErrorType = {
    message: string[]
}

type FormData = InferInput<typeof schema>

const schema = object({
    email: pipe(string(), minLength(1, 'This field is required'), email('Email is invalid')),
})


const RecoverPassword: React.FC<Props> = ({handleSwitch, t}) => {
    const options = {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
           // Authorization: `Bearer ${token}`
        },
        credentials: 'same-origin',
        mode: 'cors'
    }

    // States
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    const [errorState, setErrorState] = useState<ErrorType | null>(null)
    const [errorEmail, setErrorEmail] = useState<any>({
        status: false,
        msg: ''
    })
    const [errorPassword, setErrorPassword] = useState<any>({
        status: false,
        msg: ''
    })

    // Hooks
    const router = useRouter()
    const searchParams = useSearchParams()
    const {lang: locale} = useParams()

    const {
        control,
        handleSubmit,
        formState: {errors}
    } = useForm<FormData>({
        resolver: valibotResolver(schema),
        defaultValues: {
            email: '',
        }
    })

    const handleClickShowPassword = () => setIsPasswordShown(show => !show)
    const onSubmit: SubmitHandler<FormData> = async (data: FormData) => {
        axios.post(`${process.env.NEXT_PUBLIC_API_URL}/user/forgotPassword`, data, options)
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: `${t("recover-ok")}`,
                    text: `${t("recover-ok-text")}`,
                    showConfirmButton: false,
                    showCancelButton: false,
                })
        }).catch((error) => {
            console.log('error', error)
        })

    }

    return (
        <div
            className='flex flex-col gap-6 is-full sm:is-auto md:is-full sm:max-is-[400px] md:max-is-[unset] mbs-8 sm:mbs-11 md:mbs-0'>
            <div className='flex flex-col gap-1'>
                <div className='flex flex-row gap-1'>
                    <Typography variant='h5'>{t('passwordDimenticata')}</Typography>
                    <Locker />
                </div>
                <Typography variant='body2'
                            style={{marginRight: '5px'}}>{t('passwordDimenticataDescr')}
                </Typography>
            </div>
            <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
                <Grid container spacing={6} direction={'row'} style={{marginBottom: '10px'}} justifyContent={'end'}>
                    <Grid item xs={12} md={12}>
                        <Controller
                            name='email'
                            control={control}
                            rules={{required: true}}
                            render={({field}) => (
                                <CustomTextField
                                    {...field}
                                    autoFocus
                                    fullWidth
                                    type='email'
                                    label='Email'
                                    placeholder={t('inserisciEmail')}
                                    onChange={e => {
                                        field.onChange(e.target.value)
                                        errorState !== null && setErrorState(null)
                                        if (!e.target.value) {
                                            setErrorEmail({status: true, msg: 'Inserisci email'})
                                        } else {
                                            setErrorEmail({status: false, msg: ''})
                                        }
                                    }}
                                    {...((errorEmail.status || errorState !== null) && {
                                        error: true,
                                        helperText: errorEmail?.msg
                                    })}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Button fullWidth variant='contained' type='submit'>
                            {t("recuperaPasswordBtn")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} style={{marginTop: '25px'}}>
                        <div className='flex justify-center items-center gap-x-3 gap-y-1 flex-wrap'>

                            <Typography
                                className='text-end'
                                color='primary'
                                component={Link}
                                // href={getLocalizedUrl('/login', locale as Locale)}
                                href={'#'}
                                onClick={(event) => {
                                    handleSwitch(false)
                                }}
                            >

                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <span className="ep--arrow-left-bold"></span>
                                    <span>  {t('versoLogin')}</span>
                                </Stack>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default RecoverPassword
